<template>
  <div class="service">
    <!--====== PAGE TITLE PART START ======-->

    <div class="page-title-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-item text-center">
              <h2 class="title">Services</h2>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="index.php">Kibbutz Tech</a>
                  </li>
                </ol>
              </nav>
            </div>
            <!-- page title -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>

    <!--====== PAGE TITLE PART ENDS ======-->

    <!--====== SERVICES ITEM PART START ======-->

    <div class="services-item-area pt-90 pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 col-sm-8">
            <div class="single-services-item mt-30">
              <router-link to="/services-details?name=Mobile_App_Development">
                <!-- <a href="services-details?name=Mobile_App_Development"> -->
                <img style="width: 450px" src="assets/images/mobiledeveloper.png" alt="image" />
                <!-- </a> -->
                <!-- <a href="services-details?name=Mobile_App_Development"> -->
                <div class="services-overlay">
                  <i class="fal fa-eye-dropper"></i>
                  <h4 class="title">Mobile Application Development</h4>
                  <p>
                    Quick and
                    trustworthy mobile apps for your company's requirements
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
            <!-- single services -->
          </div>

          <div class="col-lg-4 col-md-6 col-sm-8">
            <div class="single-services-item mt-30">
              <router-link to="/services-details?name=Web_App_Development">
                <!-- <a href="services-details?name=Web_App_Development"> -->
                <img style="width: 450px" src="assets/images/webdeveloper2.png" alt="image" />
                <!-- </a> -->
                <!-- <a href="services-details?name=Web_App_Development"> -->
                <div class="services-overlay">
                  <i class="fal fa-gavel"></i>
                  <h4 class="title">
                    Web
                    <br />Development
                  </h4>
                  <p>
                    Be the first organic search result in
                    Google
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-4 col-md-6 col-sm-8">
            <div class="single-services-item mt-30">
              <router-link to="/services-details?name=Mobile_Game_Development">
                <!-- <a href="services-details?name=Mobile_Game_Development"> -->
                <img src="assets/images/game.png" alt="image" />
                <!-- </a> -->
                <!-- <a href="services-details?name=Mobile_Game_Development"> -->
                <div class="services-overlay">
                  <i class="fal fa-map-marked-alt"></i>
                  <h4 class="title">Mobile Game Development</h4>
                  <p>We develop a successful mobile game and turn it into a profitable business</p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-4 col-md-6 col-sm-8">
            <div class="single-services-item mt-30">
              <router-link to="/services-details?name=UI_UX_Design">
                <!-- <a href="services-details?name=UI_UX_Design"> -->
                <img src="assets/images/uiux2.png" alt="image" />
                <!-- </a> -->
                <!-- <a href="services-details?name=UI_UX_Design"> -->
                <div class="services-overlay">
                  <i class="fal fa-hurricane"></i>
                  <h4 class="title">
                    UI/UX
                    <br />Designing
                  </h4>
                  <p>
                    We give an impactful look to
                    your business
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
            <!-- single services -->
          </div>

          <div class="col-lg-4 col-md-6 col-sm-8">
            <div class="single-services-item mt-30" style="weight=3000px height=2000px">
              <router-link to="/services-details?name=Digital_Marketing">
                <!-- <a href="services-details?name=UI_UX_Design"> -->
                <img src="assets/images/marketing.png" alt="image" />
                <!-- </a> -->
                <!-- <a href="services-details?name=UI_UX_Design"> -->
                <div class="services-overlay">
                  <i class="fal fa-hurricane"></i>
                  <h4 class="title">
                    Digital
                    <br />Marketing
                  </h4>
                  <p>
                    Our
                    digital marketing strategies will be the best in thousands to increase the
                    impact of your business.
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
            <!-- single services -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>

    <!--====== SERVICES ITEM PART ENDS ======-->
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {
    // console.log("this.$route.params",this.$route.params.name)
    window.scrollTo(0, 0);
  }
};
</script>



 